module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"My Technologist","short_name":"My Technologist","start_url":"/","background_color":"#000","theme_color":"#000","display":"minimal-ui","icon":"src/images/mytechnologists-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"db4116378516a68da0e854651521155f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"sm":"(max-width: 575px)","md":"(max-width: 767px)","l":"(max-width: 991px)","xl":"(max-width: 1199px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FXQPGV0TRM"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
