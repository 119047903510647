// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructors-js": () => import("./../../../src/pages/instructors.js" /* webpackChunkName: "component---src-pages-instructors-js" */),
  "component---src-pages-why-us-js": () => import("./../../../src/pages/why-us.js" /* webpackChunkName: "component---src-pages-why-us-js" */),
  "component---src-templates-service-learn-page-service-learn-page-template-js": () => import("./../../../src/templates/service-learn-page/service-learn-page.template.js" /* webpackChunkName: "component---src-templates-service-learn-page-service-learn-page-template-js" */),
  "component---src-templates-service-purchase-page-service-purchase-page-template-js": () => import("./../../../src/templates/service-purchase-page/service-purchase-page.template.js" /* webpackChunkName: "component---src-templates-service-purchase-page-service-purchase-page-template-js" */)
}

